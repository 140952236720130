import { memo } from 'react'

export default memo<{ width: number; height: number; color?: string; className?: string }>(
  function OrusHorizontalLogo(props) {
    const { width, height, className } = props

    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 170 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.773 26.25C167.768 27.1666 167.766 29.8226 167.766 29.8226L133.021 29.8341C133.028 28.7546 133.021 26.25 133.021 26.25H167.773ZM163.106 15.5357C163.106 15.5357 165.318 21.3655 166.362 24.1458L161.703 24.1343C161.162 24.1333 160.654 23.6666 160.47 23.1101C159.901 21.496 159.287 19.9175 158.766 18.3034L163.106 15.5357ZM157.536 13.8568C157.536 13.8568 156.028 9.28815 155.276 7.09989H155.199C153.959 9.83835 152.983 12.7042 151.743 15.4427C150.213 17.1048 148.114 17.3512 146.153 17.8482L145.659 17.9191L145.675 17.6498L152.63 2.50615L152.816 2.16683L158.062 2.14282C158.355 2.68154 158.569 3.309 158.784 3.91872L161.688 11.2729L157.536 13.8568Z"
          fill="#00704E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.505 18.1974C152.851 21.3734 147.106 24.6934 140.271 24.139C137.541 23.8456 134.797 22.3339 133.537 19.2665C132.137 16.6293 132.284 12.6693 133.846 10.2001C136.338 6.06684 140.061 3.45885 143.921 2.21333C145.248 1.87715 147.332 1.20062 148.224 2.94102C148.579 3.99861 148.869 5.07501 149.051 6.20466C145.796 6.08877 142.97 7.6047 140.375 9.5706C138.335 11.2682 136.262 13.4847 135.456 16.171C135.223 16.977 135.345 17.9625 135.837 18.591C137.871 20.4776 140.943 19.9827 143.473 19.7019C148.335 18.8186 152.788 16.5124 157.081 13.9065L161.232 11.3225L169.713 5.89563H169.806L169.797 10.6627L162.845 15.4297L158.505 18.1974Z"
          fill="#009598"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.778 3.92451L163.136 8.06616C162.72 8.26245 162.195 8.29587 161.794 8.04528C161.055 7.27272 160.887 6.17856 160.549 5.19195C160.828 4.97793 161.29 4.97898 161.63 4.81821L163.299 3.90885L169.678 0H169.816L169.778 3.92451Z"
          fill="#ED1C22"
        />
        <path
          d="M44.7164 9.90732H47.7517L47.3671 11.6777C48.5087 10.4645 49.7688 9.62952 51.5006 9.62952C54.4613 9.62952 56.7819 11.8032 56.9659 15.3424C57.2119 20.0697 53.8292 23.5335 50.0164 23.5335C47.6833 23.5335 46.2924 22.3705 45.4124 20.8795L44.0402 27.3002H41.0049L44.7164 9.90732ZM54.0033 15.57C53.8975 13.5218 52.5768 12.2584 50.6955 12.2584C48.2626 12.2584 46.0555 14.7099 46.2036 17.5428C46.3049 19.489 47.7347 20.9297 49.7159 20.9297C52.1999 20.9297 54.1561 18.5284 54.0033 15.57Z"
          fill="#009597"
        />
        <path
          d="M67.7125 9.90723V17.9053C67.7125 19.3947 66.5148 20.6016 65.0368 20.6016H64.3918C62.9139 20.6016 61.7161 19.3947 61.7161 17.9053V9.90723H58.7881V17.899C58.7881 21.0175 61.2974 23.5475 64.3936 23.5475H65.0383C68.133 23.5475 70.6435 21.0191 70.6435 17.899V9.90723H67.7155H67.7125Z"
          fill="#009597"
        />
        <path d="M75.6265 4.80029H78.6618L74.7043 23.2558H71.6689L75.6265 4.80029Z" fill="#009597" />
        <path
          d="M77.8877 21.5104L79.2426 19.4371C80.6725 20.5247 82.2283 21.0803 83.5833 21.0803C84.9382 21.0803 85.6408 20.5247 85.6408 19.6396V19.5893C85.6408 18.5535 84.2359 18.1988 82.6801 17.7185C80.7239 17.1629 78.5403 16.3531 78.5403 13.7996V13.7493C78.5403 11.2209 80.6226 9.67969 83.2564 9.67969C84.9118 9.67969 86.7185 10.262 88.1234 11.1958L86.9194 13.3695C85.6408 12.5864 84.2859 12.1061 83.1815 12.1061C82.0025 12.1061 81.3251 12.6617 81.3251 13.4464V13.4966C81.3251 14.4572 82.7547 14.8621 84.3105 15.3674C86.2421 15.9749 88.4256 16.8585 88.4256 19.2613V19.3115C88.4256 22.1178 86.2685 23.5083 83.5087 23.5083C81.6271 23.5083 79.5449 22.8256 77.8892 21.5104H77.8877Z"
          fill="#009597"
        />
        <path
          d="M89.7158 16.6309V16.5807C89.7158 12.7638 92.4007 9.62793 96.1886 9.62793C100.403 9.62793 102.535 12.9646 102.535 16.8083C102.535 17.0861 102.51 17.3639 102.485 17.6684H92.7512C93.0769 19.8421 94.6078 21.0553 96.564 21.0553C98.0437 21.0553 99.0979 20.4997 100.151 19.4623L101.933 21.0553C100.679 22.5714 98.9469 23.5586 96.514 23.5586C92.6766 23.5586 89.7158 20.7524 89.7158 16.6309ZM99.5247 15.6453C99.3238 13.6741 98.1698 12.1312 96.1636 12.1312C94.3073 12.1312 93.002 13.572 92.7265 15.6453H99.5247Z"
          fill="#009597"
        />
        <path
          d="M26.3242 16.6937V16.6435C26.3242 12.7998 29.3956 9.68127 33.5308 9.68127C37.6657 9.68127 40.6874 12.7512 40.6874 16.5932V16.6435C40.6874 20.4871 37.6176 23.6057 33.4808 23.6057C29.3442 23.6057 26.3242 20.5358 26.3242 16.6937ZM36.9727 16.6937V16.6435C36.9727 14.6722 35.5615 12.9505 33.4808 12.9505C31.4001 12.9505 30.0389 14.622 30.0389 16.5932V16.6435C30.0389 18.6147 31.4497 20.3365 33.5308 20.3365C35.6115 20.3365 36.9727 18.665 36.9727 16.6937Z"
          fill="#006A4E"
        />
        <path
          d="M25.6513 9.90576H24.2512C20.7001 9.90576 17.8203 12.8077 17.8203 16.3862V23.2824H21.6112V16.2606C21.6112 14.7994 22.7871 13.6145 24.2371 13.6145H25.6498V9.90735L25.6513 9.90576Z"
          fill="#006A4E"
        />
        <path
          d="M3.70368 9.9324H7.46811L7.14725 11.3795C8.23127 10.3059 9.31217 9.68286 10.798 9.68286C13.6949 9.68286 16.0093 11.8032 16.1978 15.4224C16.4438 20.1638 13.0298 23.557 9.29035 23.557C7.20957 23.557 5.95113 22.6844 5.01509 21.3613L3.76443 27.3002H0L3.70214 9.9324H3.70368ZM12.5097 15.672C12.4193 13.9251 11.2746 12.8767 9.59094 12.8767C7.51016 12.8767 5.6879 14.9735 5.8203 17.5177C5.90907 19.2143 7.15661 20.3631 8.81688 20.3631C10.8478 20.3631 12.6498 18.3668 12.5097 15.672Z"
          fill="#006A4E"
        />
        <path
          d="M114.89 19.0187C116.228 19.0187 117.512 17.9603 117.512 16.0595V16.0379C117.512 14.1371 116.217 13.0787 114.89 13.0787C113.96 13.0787 113.382 13.5539 112.964 14.1479V11.0159H111.659V18.8999H112.964V18.0143C113.36 18.5435 113.949 19.0187 114.89 19.0187ZM114.58 17.8847C113.692 17.8847 112.932 17.1503 112.932 16.0595V16.0379C112.932 14.9471 113.692 14.2127 114.58 14.2127C115.447 14.2127 116.196 14.9363 116.196 16.0379V16.0595C116.196 17.1827 115.468 17.8847 114.58 17.8847Z"
          fill="#03868F"
        />
        <path
          d="M121.015 17.4315L119.432 13.1979H118.041L120.395 18.9111C120.181 19.3971 119.967 19.5591 119.592 19.5591C119.325 19.5591 119.079 19.4727 118.833 19.3431L118.394 20.3043C118.79 20.5311 119.186 20.6607 119.732 20.6607C120.641 20.6607 121.133 20.2287 121.593 19.0515L123.84 13.1979H122.492L121.015 17.4315Z"
          fill="#03868F"
        />
        <path d="M118.287 22.2002H117.692V28.2002H118.287V22.2002Z" fill="#03868F" />
        <path d="M118.287 3.90015H117.692V9.90015H118.287V3.90015Z" fill="#03868F" />
      </svg>
    )
  },
)
