import { colors, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.png'

const muktaUrl = 'https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap'
const muktaFontFamily = 'Mukta, sans-serif'

export const propulseSkin: Skin = {
  avatarUrl,
  quoteHelpBannerImageUrl,
  horizontalLogo: {
    aspectRatio: 170 / 30,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'with',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.black,
    backgroundColor: colors.sky.dark,
  },
  helpBlockTextColor: '#273344',
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: muktaFontFamily,
    fontFamilyUrl: muktaUrl,
  },
  additionalStylesheets: [muktaUrl],
  cssVariables: {
    '--skin-font': muktaFontFamily,
    '--skin-font-title': muktaFontFamily,
    '--skin-font-button': muktaFontFamily,
    '--skin-font-subtitle1': muktaFontFamily,
    '--skin-font-weight-title': fontweight.medium,
    '--skin-font-weight-subtitle1': fontweight.medium,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.medium,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': '#EFFAF2',
    '--skin-blue-200': '#D8EAD7',
    '--skin-blue-300': '#D8EAD7',
    '--skin-blue-400': '#D8EAD7',
    '--skin-blue-500': '#007461',
    '--skin-blue-600': '#007461',
    '--skin-blue-700': '#007461',
    '--skin-blue-800': '#007461',
    '--skin-blue-900': '#00453A',
    '--skin-gray-100': '#EFF0F3',
    '--skin-gray-200': '#EFF0F3',
    '--skin-gray-300': '#D9D8E1',
    '--skin-gray-400': '#C0BFC7',
    '--skin-gray-500': '#929199',
    '--skin-gray-600': '#706F76',
    '--skin-gray-700': '#273344',
    '--skin-gray-800': '#273344',
    '--skin-gray-900': '#273344',
    '--skin-green-100': '#EBF9F1',
    '--skin-green-200': '#D8F3E2',
    '--skin-green-300': '#89DAA8',
    '--skin-green-400': '#61CD88',
    '--skin-green-500': '#3AC26E',
    '--skin-green-600': '#28A156',
    '--skin-green-700': '#00752D',
    '--skin-green-800': '#005922',
    '--skin-green-900': '#003715',
    '--skin-yellow-100': '#FFFCF4',
    '--skin-yellow-200': '#FFF7DF',
    '--skin-yellow-300': '#FFF1C9',
    '--skin-yellow-400': '#FEEBB4',
    '--skin-yellow-500': '#FEE394',
    '--skin-yellow-600': '#EFCE6F',
    '--skin-yellow-700': '#D1AA36',
    '--skin-yellow-800': '#846710',
    '--skin-yellow-900': '#5A4301',
    '--skin-orange-100': '#FFF6EE',
    '--skin-orange-200': '#FFEDDD',
    '--skin-orange-300': '#FFDBBB',
    '--skin-orange-400': '#FFB778',
    '--skin-orange-500': '#FFA656',
    '--skin-orange-600': '#FF7B44',
    '--skin-orange-700': '#D85D29',
    '--skin-orange-800': '#8B320E',
    '--skin-orange-900': '#471905',
    '--skin-red-100': '#FFEFEF',
    '--skin-red-200': '#FEDFDF',
    '--skin-red-300': '#FDC0C0',
    '--skin-red-400': '#FB8181',
    '--skin-red-500': '#FB6161',
    '--skin-red-600': '#CB2828',
    '--skin-red-700': '#8F0D0D',
    '--skin-red-800': '#5B0000',
    '--skin-red-900': '#410000',
    '--skin-white': '#FFFFFF',
    '--skin-black': '#000000',
    '--skin-linen': '#FFFFFF',
    '--skin-sky-dark': '#EFFAF2',
    '--skin-sky-light': '#EFFAF2',
    // we use a gradient, even if its flat for this theme, because the variable is used as a background image
    '--skin-sky-gradient': 'linear-gradient(#EFFAF2, #EFFAF2)',
    '--skin-periwinkle-dark': '#EFFAF2',
    '--skin-periwinkle-light': '#EFFAF2',
    '--skin-periwinkle-gradient': 'linear-gradient(#EFFAF2, #EFFAF2)',
    '--skin-mindaro-dark': '#E1F5A8',
    '--skin-mindaro-light': '#EFF9CE',
    '--skin-mindaro-gradient': 'radial-gradient(400% 100% at 0% 100%, #E1F5A8 0%, #E4F5B5 100%)',
    '--skin-jasmine-dark': '#FFECA8',
    '--skin-jasmine-light': '#FFF8DE',
    '--skin-jasmine-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFECA8 0%, #FFF1BE 100%)',
    '--skin-peach-dark': '#FFC4A2',
    '--skin-peach-light': '#FFE6D5',
    '--skin-peach-gradient': 'radial-gradient(400% 100% at 0% 100%, #FFC4A2 0%, #FFD0B4 100%)',
  },
}
